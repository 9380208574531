import React from 'react';
import { withRouter, useHistory } from "react-router-dom";
// import logo from './logo.svg';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './css/App.css';
import Routes from "./routes";
// import { UserService } from './Services/UserService';
import MySnackbarContent from './components/SnackBar';
import ProgressDialog from './components/ProgressDialog';
import {
	Snackbar,
	Dialog as DialogMUI,
	DialogActions,
	DialogContent,
	DialogContentText,
	Button,
	DialogTitle,
	CssBaseline,
	Fade,
	LinearProgress,
	FormControl,
	OutlinedInput,
	ThemeProvider,
	makeStyles,
	FormHelperText,
} from '@material-ui/core';
import * as Chart from "chart.js";
import AppDrawer from './AppDrawer';
import MyAppBar from './MyAppBar';
import { AppContext } from './AppContextProvider';
import { useContext } from 'react';
import CreateLeadDialog from './LeadPage/CreateLeadDialog';
import ViewLeadDialog from './LeadPage/ViewLeadDialog';
import ViewReKYCLeadDialog from './RekycLeadPage/ViewLeadDialog';
import ViewClosureLeadDialog from './ClosureLeadPage/ViewLeadDialog';
import queryString from 'query-string';
import FillFormDialog from './Forms/FillFormDialog';
import { UserService } from './Services/UserService';
import { DashboardContextProvider } from './DashboardContext';
import path from './path';
import { checkAuthorization } from './Utils'

Chart.Tooltip.positioners.custom = function (elements, position) {
	if (!elements.length) {
		return false;
	}
	var offset = 0;
	//adjust the offset left or right depending on the event position
	if (elements[0]._chart.width / 2 > position.x) {
		offset = 20;
	} else {
		offset = -120;
	}

	var offsetY = 0;
	if (elements[0]._chart.height / 2 > position.y) {
		offsetY = 20;
	} else {
		offsetY = -200;
	}
	return {
		x: position.x + offset,
		y: position.y + offsetY
	}
}

Chart.Tooltip.positioners.sales = function (elements, position) {
	// if (!elements.length) {
	//   return false;
	// }
	// var offset = 0;
	// //adjust the offset left or right depending on the event position
	// if (elements[0]._chart.width / 2 > position.x) {
	//   offset = 20;
	// } else {
	//   offset = -120;
	// }

	// var offsetY = 0;
	// if (elements[0]._chart.height / 2 > position.y) {
	// 	offsetY = 20;
	// } else {
	// 	offsetY = -200;
	// }
	var tooltip = this;

	if (!elements.length) {
		return false;
	}
	var offset = 0;
	//adjust the offset left or right depending on the event position
	if (elements[0]._chart.width / 2 > position.x) {
		offset = 20;
	} else {
		offset = -(tooltip._view.width + 20);
	}

	var offsetY = 0;
	if (elements[0]._chart.height / 2 > position.y) {
		offsetY = 20;
	} else {
		offsetY = -140;
	}

	return {
		x: position.x + offset,
		y: position.y + offsetY
	}
}

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	sectionMobile: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	accountCircle: {
		marginRight: theme.spacing(3)
	},
}));


function App(props) {

	const classes = useStyles();
	const { state,
		initializeTheme,
		handleSBClose,
		closeConfirmDialog,
		closeConfirmDialog1D,
		handleConnectionChange,
		userHasAuthenticated,
		handleLogout,
		setAuthenticating,
		showProgressDialog,
		closeProgressDialog,
		setDialogFieldText,
		closeNewLead,
		closeLead,
		closeNewForm,
		closeRekycLead,
		closeClosureLead
	} = useContext(AppContext);
	const history = useHistory();

	React.useEffect(() => {
		handleConnectionChange();
		window.addEventListener('online', handleConnectionChange());
		window.addEventListener('offline', handleConnectionChange());
		initializeTheme();
		let qString = queryString.parse(props.location.search);
		if (localStorage.getItem('acumen.session') === null && !qString.user_type && !qString.user_code) {
			history.push(path.LOGIN);
		}
		try {
			if (state.user !== null) {
				userHasAuthenticated(true);
			}
			if (localStorage.getItem('acumen.session') || (qString.user_type && qString.user_code)) {
				userHasAuthenticated(true);
				showProgressDialog('Loading...');
				// if(qString.user_type && qString.user_code){
				// 	localStorage.setItem('acumen.user_type', qString.user_type);
				// 	localStorage.setItem('acumen.user_code', qString.user_code);
				// }
				// history.push({pathname: props.location.pathname, search: });
				UserService.authenticateToken(qString.user_type, qString.user_code)
					.then((data) => {
						// console.log(data);
						if (data.auth) {
							// updateUser(data);
							userHasAuthenticated(true);
							let requestedPath = props.location.pathname
							if (props.location.pathname.includes("/tools/upload/")) {
								requestedPath = path.UPLOAD
							} else if (props.location.pathname.includes("/tools/rekyc/upload/")) {
								requestedPath = path.REKYC_UPLOAD
							}
							const authorized = checkAuthorization(requestedPath, state && state.user ? state.user.EmpUtype : null);
							history.push(authorized ? props.location.pathname : path.DASHBOARD);
						} else {
							handleLogout();
							history.push(path.LOGIN);
						}
						closeProgressDialog();
						setAuthenticating(false);
					})
					.catch((error) => {
						handleLogout();
						closeProgressDialog();
						setAuthenticating(false);
					});
				closeProgressDialog();
			}
		} catch (e) {
			if (e !== 'No current user') {
				alert(e);
			}
			closeProgressDialog();
			setAuthenticating(false);
		}

		return () => {
			window.removeEventListener('online', handleConnectionChange);
			window.removeEventListener('offline', handleConnectionChange);
		}
	}, [true]);

	return (
		<ThemeProvider theme={state.theme}>
			<DashboardContextProvider>
				<div className="App container">
					<div className={classes.root}>
						<Fade
							in={state.linearLoading}
							style={{
								transitionDelay: state.linearLoading ? '50ms' : '0ms',
							}}
							unmountOnExit
						>
							<LinearProgress
								id="test"
								style={{ height: 5, position: "fixed", top: 64, width: "98.5%" }} />
						</Fade>
						<CssBaseline />
						<MyAppBar />
						{state.isAuthenticated &&
							<AppDrawer />
						}
					</div>
					<Routes style={{ width: "50%" }} />
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={state.sb.open}
						autoHideDuration={state.sb.autohide}
						onClose={handleSBClose}>
						<MySnackbarContent
							onClose={handleSBClose}
							variant={state.sb.varient}
							message={state.sb.message}
						/>
					</Snackbar>
					<ProgressDialog open={state.progressOpen} message={state.progressMessage} />
					<DialogMUI
						style={{ zIndex: 2000 }}
						disableEnforceFocus
						open={state.openDialog}
						onClose={closeConfirmDialog}
						fullWidth={true}
						maxWidth='sm'
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description">
						{state.dialog_title ? <DialogTitle id="alert-dialog-title">{state.dialog_title}</DialogTitle> : ''}
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{state.dialog_message}
							</DialogContentText>
							{
								state.showfield &&
								<div>
									<FormControl variant="outlined" key="field" fullWidth>
										<OutlinedInput
											fullWidth
											placeholder={state.fieldHint}
											defaultValue={state.fieldValue}
											id="outlined-adornment-field"
											onChange={(e) => {
												e.target.value && e.target.value.length > 0 && setDialogFieldText(e.target.value)
											}}
											aria-describedby="outlined-weight-helper-text"
											inputProps={{
												'aria-label': 'rate',
											}}
											labelWidth={0}
											multiline
											error={state.fieldError || false}
											rowsMax={2}
										/>
										{
											state.fieldError &&
											<FormHelperText error={true}>{state.fieldError}</FormHelperText>
										}
									</FormControl>
								</div>
							}
						</DialogContent>
						<DialogActions>
							<Button onClick={closeConfirmDialog} color="primary">
								{state.confirmAction ? state.cancel_title : "Ok"}
							</Button>
							{
								state.confirmAction &&
								<Button onClick={(e) =>
									state.showfield ?
										state.confirmAction(state.dialog_field_text)
										:
										state.confirmAction()
								}
									color="primary"
									autoFocus>
									{state.accept_title}
								</Button>
							}
						</DialogActions>
					</DialogMUI>
					<DialogMUI
						style={{ zIndex: 2000 }}
						disableEnforceFocus
						open={state.openDialog_1D}
						onClose={closeConfirmDialog1D}
						fullWidth={true}
						maxWidth='sm'
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description">
						{state.dialog_title_1D ? <DialogTitle id="alert-dialog-title">{state.dialog_title_1D}</DialogTitle> : ''}
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{state.dialog_message_1D}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									closeConfirmDialog();
									if (state.confirmAction_1D) {
										state.confirmAction_1D();
									}
								}} color="primary">
								{state.button_title_1D ? state.button_title_1D : "Ok"}
							</Button>
						</DialogActions>
					</DialogMUI>
					<CreateLeadDialog
						open={state.openNewLead}
						id={state.openNewLeadId}
						onClose={closeNewLead} />
					<ViewLeadDialog
						open={state.openLead}
						id={state.openLeadId}
						onClose={closeLead} />
					<ViewReKYCLeadDialog
						open={state.openRekycLead}
						id={state.openRekycLeadId}
						onClose={closeRekycLead} />
					<ViewClosureLeadDialog
						open={state.openClosureLead}
						id={state.openClosureLeadId}
						onClose={closeClosureLead} />
					<FillFormDialog
						open={state.openNewForm}
						id={state.openNewFormId}
						type={state.openNewFormType}
						onClose={closeNewForm} />
				</div>
			</DashboardContextProvider>
		</ThemeProvider>
	);
}

export default withRouter(App);
