import React, { useContext, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Select, MenuItem, Input, CircularProgress, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from 'clsx';
import moment from "moment";
import ReactTooltip from 'react-tooltip';
import { format, colorGenerator, getFinancialYearDates } from '../Utils';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import INDIA_TOPO_JSON from './india.topo.json';
import { LeadService } from "../Services/LeadService";
import { STATUS, USERTYPES } from "../Constants";
import { AppContext } from "../AppContextProvider";

const PROJECTION_CONFIG = {
    scale: 1000,
    center: [78.9629, 22.5937]
};

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5,
        minHeight: 200,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        marginTop: 10
    },
    itemDivider: {
        borderBottomColor: 'rgba(255,255,255,0.2)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8,
        marginLeft: 8
    },
    loader: {
        marginTop: 40
    },
    geographyStyle: {
        default: {
            outline: 'none'
        },
        hover: {
            fill: '#ccc',
            transition: 'all 250ms',
            outline: 'none'
        },
        pressed: {
            outline: 'none'
        }
    }
}));

const TopRankingStates = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const count = 35;
    const { report, className, count: counter } = props;
    const { state } = useContext(AppContext)
    // const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState([]);
    const [range, setRange] = React.useState(props.range || 'today');
    const [loading, setLoading] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const colors = colorGenerator(count);
    const DEFAULT_COLOR = '#aaa';

    const rangeOptions = [
        {
            label: 'Today',
            value: 'today',
            startdate: moment().startOf('d'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 7 Days',
            value: 'last_7',
            startdate: moment().startOf('d').add(-7, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 30 Days',
            value: 'last_30',
            startdate: moment().startOf('d').add(-30, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'This Financial Year',
            value: 'this_year',
            ...getFinancialYearDates()
        }
    ];

    React.useEffect(() => {
        let selectedRange = rangeOptions.filter(r => r.value === range)[0];
        setLoading(true);
        let referral_ids = null;
        if ([USERTYPES.FRAN, USERTYPES.RM].includes(state.user.EmpUtype)) {
            referral_ids = state.user.EmpCode
        }
        const controller = new AbortController()
        LeadService.getTopLocations('state', moment(selectedRange.startdate).utc().format(format), moment(selectedRange.enddate).utc().format(format), STATUS.ACTIVATED, count, referral_ids, controller.signal)
            .then(data => {
                if (data.stats) {
                    data.stats.forEach((d, i) => {
                        d.color = colors[i];
                    })
                    setData(data.stats);
                }
                setLoading(false);
            })
            .catch(error => {
                if (!controller.signal.aborted) {
                    console.log(error);
                }
                setLoading(false);
            })

        return () => controller.abort()
    }, [range, counter]);

    const onMouseEnter = (geo, current = { count: 0 }) => {
        return () => {
            if (current.location === undefined) {
                setTooltipContent(`${geo.properties.name}: ${current.count}`);
            } else {
                setTooltipContent(`${current.location}: ${current.count}`);
            }
        };
    };

    const onMouseLeave = () => {
        setTooltipContent('');
    };

    return (
        <Grid item xl={4} lg={6} xs={12}>
            <Card
                ref={forwardedRef}
                className={clsx(classes.root, className)}
            >
                <CardHeader
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{ align: 'left' }}
                    action={
                        <>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-2"
                                value={range}
                                onChange={(e) => setRange(e.target.value)}
                                className={classes.select}
                                input={<Input disableUnderline={true} />}>
                                {rangeOptions.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                            </Select>
                        </>
                    } />
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress />
                            </div>
                            :
                            <div>
                                <ReactTooltip>{tooltipContent}</ReactTooltip>
                                <ComposableMap
                                    projectionConfig={PROJECTION_CONFIG}
                                    projection="geoMercator"
                                    width={800}
                                    height={620}
                                    data-tip=""
                                >
                                    <Geographies geography={INDIA_TOPO_JSON}>
                                        {({ geographies }) =>
                                            geographies.map(geo => {
                                                const current = data.find(s => s.location === geo.properties.name);
                                                return (
                                                    <Geography
                                                        key={geo.rsmKey}
                                                        geography={geo}
                                                        fill={current ? current.color : DEFAULT_COLOR}
                                                        className={classes.geographyStyle}
                                                        onMouseEnter={onMouseEnter(geo, current)}
                                                        onMouseLeave={onMouseLeave}
                                                    />
                                                );
                                            })
                                        }
                                    </Geographies>
                                </ComposableMap>
                            </div>
                    }
                </CardContent>
            </Card>
        </Grid>
    );
};

TopRankingStates.propTypes = {
    report: PropTypes.object.isRequired,
};

export default TopRankingStates;