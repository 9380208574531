import React, { useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Select, MenuItem, Input, List, ListItem, Typography, ListItemText, Grid, CircularProgress } from "@material-ui/core";
import { format, formatNumber, getFinancialYearDates, getRelativeTime } from "../Utils";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { LeadService } from "../Services/LeadService";
import { STATUS, USERTYPES } from "../Constants";
import moment from "moment";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5,
        minHeight: 275,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    loader: {
        marginTop: 40
    }
}));

const TableStats = ({ forwardedRef, ...props }) => {
    const { state } = useContext(AppContext);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { report, className, count } = props;
    const [data, setData] = useState([]);
    const [amount, setAmount] = useState(0);
    const [tat, setTat] = useState(undefined);

    const [range, setRange] = React.useState(props.range || 'today');
    const rangeOptions = [
        {
            label: 'Today',
            value: 'today',
            startdate: moment().startOf('d'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 7 Days',
            value: 'last_7',
            startdate: moment().startOf('d').add(-7, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 30 Days',
            value: 'last_30',
            startdate: moment().startOf('d').add(-30, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'This Financial Year',
            value: 'this_year',
            ...getFinancialYearDates()
        }
    ];

    React.useEffect(() => {
        setLoading(true);
        let selectedRange = rangeOptions.find(r => r.value === range);
        let referral_ids = null;
        if ([USERTYPES.FRAN, USERTYPES.RM].includes(state.user.EmpUtype)) {
            referral_ids = state.user.EmpCode
        }
        const controller = new AbortController()
        Promise.all([
            LeadService.getLeadStats1(moment(selectedRange.startdate).utc().format(format), moment(selectedRange.enddate).utc().format(format), STATUS.ACTIVATED, referral_ids, controller.signal),
            LeadService.getTotalAmountCollected(moment(selectedRange.startdate).utc().format(format), moment(selectedRange.enddate).utc().format(format), referral_ids, controller.signal),
            LeadService.getTAT(moment(selectedRange.startdate).utc().format(format), moment(selectedRange.enddate).utc().format(format), referral_ids, controller.signal),
        ])
            .then(datas => {
                if (datas[0]) {
                    setData(datas[0]);
                }
                if (datas[1] && datas[1].amount) {
                    setAmount(datas[1].amount);
                } else {
                    setAmount(0)
                }
                if (datas[2]) {
                    let t = datas[2].average_tat
                    setTat(getRelativeTime(t.value || 0, t.unit || 'hour'));
                } else {
                    setTat(undefined)
                }
                setLoading(false);
            })
            .catch(error => {
                if (!controller.signal.aborted) {
                    console.log(error);
                }
                setLoading(false);
            })
        return () => controller.abort()
    }, [range, count]);

    return (
        <Grid item xl={4} lg={6} xs={12}>
            <Card
                ref={forwardedRef}
                className={clsx(classes.root, className)}
            //onMouseEnter={() => setIsShown(true)}
            //onMouseLeave={() => setIsShown(false)}
            >
                <CardHeader
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{ align: 'left' }}
                    action={
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-2"
                            value={range}
                            onChange={(e) => setRange(e.target.value)}
                            className={classes.select}
                            input={<Input disableUnderline={true} />}>
                            {rangeOptions.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                        </Select>
                    } />
                <Divider />
                <CardContent className={classes.content}>
                    <List>
                        <ListItem
                            classes={{ divider: classes.itemDivide }}
                            divider
                            dense
                        >
                            <ListItemText
                                primary=""
                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                style={{ flex: 2 }}
                            />
                            <Typography variant="subtitle2" style={{ flex: 1 }} align="right">Yes</Typography>
                            <Typography variant="subtitle2" style={{ flex: 1 }} align="right">No</Typography>
                        </ListItem>
                    </List>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress />
                            </div>
                            :
                            data && (
                                <div>
                                    <List>
                                        <ListItem
                                            dense
                                        >
                                            <ListItemText
                                                primary="E-Signed"
                                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                                style={{ flex: 2 }}
                                            />
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.esigned ? (data.esigned.yes || 0) : 0}</Typography>
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.esigned ? (data.esigned.no || 0) : 0}</Typography>
                                        </ListItem>
                                        <ListItem
                                            dense
                                        >
                                            <ListItemText
                                                primary="Aadhaar Linked"
                                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                                style={{ flex: 2 }}
                                            />
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.aadhaar_linked ? (data.aadhaar_linked.yes || 0) : 0}</Typography>
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.aadhaar_linked ? (data.aadhaar_linked.no || 0) : 0}</Typography>
                                        </ListItem>
                                        <ListItem
                                            dense
                                        >
                                            <ListItemText
                                                primary="DigiLocker Fetched"
                                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                                style={{ flex: 2 }}
                                            />
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.aadhaar_fetched ? (data.aadhaar_fetched.yes || 0) : 0}</Typography>
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.aadhaar_fetched ? (data.aadhaar_fetched.no || 0) : 0}</Typography>
                                        </ListItem>
                                        <ListItem
                                            dense
                                        >
                                            <ListItemText
                                                primary="KRA Registered"
                                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                                style={{ flex: 2 }}
                                            />
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.kra_registered ? (data.kra_registered.yes || 0) : 0}</Typography>
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{data.kra_registered ? (data.kra_registered.no || 0) : 0}</Typography>
                                        </ListItem>
                                    </List>
                                    <Divider variant="middle" />
                                    <List>
                                        <ListItem
                                            dense
                                        >
                                            <ListItemText
                                                primary="Total Amount Collected"
                                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                                style={{ flex: 2 }}
                                            />
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{formatNumber(amount || 0)}</Typography>
                                        </ListItem>
                                        <ListItem
                                            dense
                                        >
                                            <ListItemText
                                                primary="Average Turn Around Time"
                                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                                style={{ flex: 2 }}
                                            />
                                            <Typography color="inherit" style={{ flex: 1 }} align="right">{tat || '-'}</Typography>
                                        </ListItem>
                                    </List>
                                </div>
                            )
                    }
                </CardContent>
            </Card>
        </Grid>
    );
};

TableStats.propTypes = {
    report: PropTypes.object.isRequired,
};

export default TableStats;