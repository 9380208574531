import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, colors, Divider, Grid, Typography, Paper, Tooltip, Table, TablePagination, TableBody, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PublishIcon from '@material-ui/icons/Publish';
import GetApp from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import { useHistory, useParams } from "react-router";
import { AppContext } from '../AppContextProvider';
import path from "../path";
import SingleDate from "../components/SingleDate";
import ViewLogs from "../components/ViewLogs";
import moment from "moment";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { TABLE_COUNTS } from "../Constants";
import { StyledTableCell, format_fancy, BootstrapTooltip, getTimeInIST, toInt, format, format_display_withtime, openLink } from "../Utils";
import { RowCountOptions } from "../Config";
import { LeadService } from "../Services/LeadService";
import BlueButton from '../components/ColorButton';

export const UPLOAD_TYPES = {
    nse: {
        label: "NSE",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
    mcx: {
        label: "MCX",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
    ncdex: {
        label: "NCDEX",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
    bse: {
        label: "BSE",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
    bse_star: {
        label: "BSE Star",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
    annexure_j: {
        label: "ANNEXURE J",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: true,
        showBatchDownload: false,
        rekyc_module_enabled: false,
    },
    cdsl: {
        label: "CDSL",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
    ckyc: {
        label: "CKYC",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
}

export const REKYC_UPLOAD_TYPES = {
    nse: {
        label: "NSE",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: true,
    },
    mcx: {
        label: "MCX",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: true,
    },
    ncdex: {
        label: "NCDEX",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: true,
    },
    bse: {
        label: "BSE",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: true,
    },
    bse_star: {
        label: "BSE Star",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
    annexure_j: {
        label: "ANNEXURE J",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: true,
        showBatchDownload: false,
        rekyc_module_enabled: true,
    },
    cdsl: {
        label: "CDSL",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: true,
    },
    ckyc: {
        label: "CKYC",
        showPreviousLogs: true,
        showStats: true,
        showBatchAPI: false,
        showBatchDownload: true,
        rekyc_module_enabled: false,
    },
}

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    },
    stateSelection: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    section: {
        paddingTop: theme.spacing(2),
    }
}));

function UploadPage(props) {

    const { type } = useParams()
    const history = useHistory()
    const isReKYCFlow = window.location.pathname.includes('rekyc/upload')

    const config = isReKYCFlow ? REKYC_UPLOAD_TYPES[type] : UPLOAD_TYPES[type]
    
    if (!config) {
        history.push(path.NOT_FOUND)
        return null
    }

    const classes = useStyles()
    const { setTitle, showProgressDialog, showSnackBar, closeProgressDialog, showConfirmDialog, closeConfirmDialog, setLinearLoading } = useContext(AppContext);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'month'))
    const [endDate, setEndDate] = useState(moment())
    const [generate, setGenerate] = useState(false);
    const [stats, setStats] = useState(null);

    const [viewLogsOpen, setViewLogsOpen] = useState(false);
    const [viewLogsData, setViewLogsData] = useState(null);

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'Run Date', align: 'left', numeric: false, disablePadding: true, label: 'Run Date', sortable: false },
        { id: 'identifier', align: 'justify', numeric: false, disablePadding: false, label: 'Identifier', sortable: false },
        { id: 'status', align: 'center', numeric: false, disablePadding: true, label: 'Status', sortable: false },
        { id: 'has_logs', align: 'center', numeric: false, disablePadding: true, label: 'View Logs', sortable: false },
        { id: 'url', align: 'center', numeric: false, disablePadding: true, label: 'Download', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = useState(localStorage.getItem(TABLE_COUNTS.upload_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.upload_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [fetchPage, setFetchPage] = useState(false);
    const [currentRows, setCurrentRows] = useState([]);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.upload_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if (currentPageNumber > page) {
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    useEffect(() => {
        setTitle(isReKYCFlow ? "Re-KYC Uploads": "KYC Uploads")
        showProgressDialog()
        fetchStats()
    }, [type, config.rekyc_module_enabled])

    const fetchStats = (resetFilter = false) => {
        LeadService.taskStats(type, config.rekyc_module_enabled)
            .then(data => {
                if (data) {
                    setStats(data)
                    if (resetFilter && data.last_run_date && !generate) {
                        // setStartDate(getTimeInIST(data.last_run_date).startOf('day'))
                        setEndDate(getTimeInIST(data.last_run_date).endOf('day'))
                    }
                    setGenerate(true);
                }
            })
            .catch(error => console.error(error))
            .finally(() => closeProgressDialog())
    }

    React.useEffect(() => {
        if (generate) {
            setLinearLoading(true);
            setCurrentPageNumber(0);
            showProgressDialog();
            LeadService.getTaskInstancesCount(type, moment(startDate).startOf('d').utc().format(format), moment(endDate).endOf('d').utc().format(format), config.rekyc_module_enabled)
                .then(data => {
                    console.log(data);
                    setTotalItems(toInt(data.count));
                    setFetchPage(true);
                    if (toInt(data.count) === 0) {
                        setCurrentRows([]);
                    }
                    setLinearLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                });
            setGenerate(false);
        }
    }, [generate]);

    React.useEffect(() => {
        let rows = [];
        if (fetchPage) {
            setLinearLoading(true);
            LeadService.getTaskInstances(type, moment(startDate).startOf('d').utc().format(format), moment(endDate).endOf('d').utc().format(format), currentPageNumber * numItemsPerPage, numItemsPerPage, null, null, config.rekyc_module_enabled)
                .then((data) => {
                    console.log(data);
                    if (data && data.transactions && data.transactions.length > 0) {
                        data.transactions.forEach((element, index) => {
                            rows.push(
                                <TableRow key={index} hover >
                                    <StyledTableCell scope="row" align="center" padding='none' style={{ width: 20 }}>
                                        {index + 1 + (currentPageNumber * numItemsPerPage)}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="justify" padding='none'>
                                        {element.created_date ? getTimeInIST(element.created_date).format(format_display_withtime) : ''}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="justify" padding='none'>
                                        {element.identifier || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center" padding='none'>
                                        {element.status || '-'}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
                                        {
                                            element.has_logs && (
                                                <BootstrapTooltip arrow title="Open view logs">
                                                    <Button
                                                        size='small'
                                                        style={{ marginRight: 16 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setViewLogsData({
                                                                id: element.id,
                                                                type: type,
                                                                label: `${config.label}${element.created_date ? `- ${getTimeInIST(element.created_date).format(format_display_withtime)}` : ''}`,
                                                            })
                                                            setViewLogsOpen(true)
                                                        }}>View Logs</Button>
                                                </BootstrapTooltip>
                                            )
                                        }
                                    </StyledTableCell>
                                    {config.showBatchDownload ? (
                                        <StyledTableCell scope="row" align="right" padding='none' style={{ paddingRight: 16 }}>
                                            {
                                                element.url ? (
                                                    <GetApp fontSize='small' style={{ marginTop: 8 }} onClick={download(element.url)} />
                                                ) : null
                                            }
                                        </StyledTableCell>
                                    ) : null}
                                </TableRow>
                            );
                        });
                    }
                    setCurrentRows(rows);
                    setLinearLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                })
                .finally(() => closeProgressDialog());
        }
        setFetchPage(false);
    }, [fetchPage]);

    const triggerUpload = operation => () => {
        showConfirmDialog("Confirm upload request?", undefined, () => {
            closeConfirmDialog();
            showProgressDialog('Requesting...');
            const p = config.rekyc_module_enabled ? LeadService.attemptReKYCTask(null, type, "batch_api", operation) : LeadService.attemptTask(null, type, "batch_api")
            p
                .then(data => {
                    if (data.success) {
                        showSnackBar(data.message, "success")
                        fetchStats(true)
                    } else {
                        showSnackBar(data.error, "error")
                    }
                })
                .catch(error => {
                    showSnackBar('Something went wrong. Please try again later.', 'error');
                })
                .finally(() => closeProgressDialog())
        });
    }

    const triggerDownload = operation => () => {
        showConfirmDialog("Confirm download request?", undefined, () => {
            closeConfirmDialog();
            showProgressDialog('Requesting...');
            const p = config.rekyc_module_enabled ? LeadService.attemptReKYCTask(null, type, "batch_download", operation) : LeadService.attemptTask(null, type, "batch_download")
            p
                .then((data) => {
                    if (data.success) {
                        if (data.file_url) {
                            openLink(data.file_url)
                        }
                        showSnackBar(data.message, "success")
                        fetchStats(true)
                    } else {
                        showSnackBar(data.error, "error")
                    }
                })
                .catch(error => {
                    console.log(error);
                    showSnackBar('Failed to Download. Please try again', 'error')
                })
                .finally(() => {
                    closeProgressDialog();
                });
        });
    }

    const download = (url) => e => {
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        link.rel = "noopener noreferrer"
        link.download = url.substring(url.lastIndexOf("/") + 1);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    return (
        <div className="Home">
            <div className="lander">
                <div>
                    <Grid container direction="row" spacing={4} justify="center">
                        <Grid item xl={10} lg={10} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader
                                    title={config.label}
                                    titleTypographyProps={{ align: 'left' }} />
                                <Divider />
                                <CardContent>
                                    <Grid container justify="space-between" alignItems="center" spacing={1}>
                                        <Grid item xs={6}>
                                            {
                                                config.showStats && (
                                                    <>
                                                        <div style={{ textAlign: 'left' }}>
                                                            <Typography variant="body1" align="left" style={{ padding: 12, color: 'black', fontWeight: 700 }} component="span">Pending Uploads</Typography>
                                                            <Typography variant="body1" align="left" style={{ padding: 12, color: 'black' }} component="span">{stats ? (stats.count || 0) : "-"}</Typography>
                                                        </div>
                                                        <div style={{ textAlign: 'left' }}>
                                                            <Typography variant="caption" color="textSecondary" align="left" style={{ padding: 12 }} component="span">Last ran on {stats && stats.last_run_date ? getTimeInIST(stats.last_run_date).format(format_fancy) : "-"}</Typography>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Grid>
                                        {
                                            config.showBatchAPI && (
                                                <Grid item xs>
                                                    <BlueButton startIcon={<PublishIcon />} variant="contained" onClick={triggerUpload(1)}>{config.rekyc_module_enabled ? 'Upload Batch - Add' : 'Upload Batch'}</BlueButton>
                                                    {
                                                        config.rekyc_module_enabled && (
                                                            <BlueButton startIcon={<PublishIcon />} variant="contained" onClick={triggerUpload(0)} style={{ marginLeft: 8 }}>Upload Batch - Update</BlueButton>
                                                        )
                                                    }
                                                </Grid>
                                            )
                                        }
                                        {
                                            config.showBatchDownload && (
                                                <Grid item xs>
                                                    <BlueButton startIcon={<GetApp />} variant="contained" onClick={triggerDownload(1)}>{config.rekyc_module_enabled ? 'Batch File - Add' : 'Batch File Download'}</BlueButton>
                                                    {
                                                        config.rekyc_module_enabled && (
                                                            <BlueButton startIcon={<GetApp />} variant="contained" onClick={triggerDownload(0)} style={{ marginLeft: 8 }}>Batch File - Modify</BlueButton>
                                                        )
                                                    }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={10} lg={10} xs={12}>
                            <Paper className={classes.section}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item container xs={12} lg={12} alignItems="center" spacing={2} style={{ marginLeft: 8 }}>
                                        <Grid item>
                                            <div style={{ maxWidth: 170 }}>
                                                <SingleDate
                                                    label="From"
                                                    date={startDate}
                                                    margin='dense'
                                                    maxDate={moment()}
                                                    setDate={(date) => {
                                                        setStartDate(date);
                                                    }}
                                                    onKeyUp={(event) => {
                                                        event.preventDefault();
                                                        if (event.key === 'Enter') {
                                                            !generate && setGenerate(true)
                                                        }
                                                    }} />
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ maxWidth: 170 }}>
                                                <SingleDate
                                                    label="To"
                                                    margin='dense'
                                                    date={endDate}
                                                    minDate={startDate}
                                                    maxDate={moment().add(1, 'd')}
                                                    setDate={(date) => {
                                                        setEndDate(date);
                                                    }}
                                                    onKeyUp={(event) => {
                                                        event.preventDefault();
                                                        if (event.key === 'Enter') {
                                                            !generate && setGenerate(true)
                                                        }
                                                    }} />
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Refresh">
                                                <Button variant="contained" aria-label="Search" color="secondary" onClick={() => { !generate && setGenerate(true) }}>
                                                    Search
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Divider style={{ marginBottom: 0, marginTop: 10 }} />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <div style={{ minHeight: 100, overflowY: 'auto', display: 'block' }}>
                                            <Table className={classes.table} stickyHeader>
                                                <EnhancedTableHead
                                                    order={"asc"}
                                                    orderBy={"created_date"}
                                                    onRequestSort={() => { }}
                                                    rows={config.showBatchDownload ? columns : columns.filter(c => c.id !== 'url')} />
                                                <TableBody>
                                                    {currentRows}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        {
                                            totalItems === 0 &&
                                            <Typography color="error" variant="subtitle2">No Instances to list</Typography>
                                        }
                                        <TablePagination
                                            rowsPerPageOptions={RowCountOptions}
                                            component="div"
                                            count={totalItems}
                                            rowsPerPage={numItemsPerPage}
                                            page={currentPageNumber}
                                            backIconButtonProps={{
                                                'aria-label': 'Previous Page',
                                            }}
                                            nextIconButtonProps={{
                                                'aria-label': 'Next Page',
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            classes={{ selectRoot: classes.selectRoot }} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <ViewLogs
                id={viewLogsData && viewLogsData.id}
                type={viewLogsData && viewLogsData.type}
                label={viewLogsData && viewLogsData.label}
                open={viewLogsOpen}
                rekyc={config.rekyc_module_enabled}
                close={() => {
                    setViewLogsOpen(false)
                    setViewLogsData(null)
                }}
            />
        </div>
    )
}

export default UploadPage;